import './appNavbarBrand.scss';

import * as React from 'react';

/**
 * Navbar brand image for the app. 
 */
export const AppNavbarBrand = (props: {children: React.ReactNode}) => {
    return (
        <div className="navbar-brand">
            {props.children}
        </div>
        );
};