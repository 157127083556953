import 'moment/locale/en-gb';
import 'moment/locale/ru';
import 'moment/locale/lv';
import 'moment/locale/de';
import 'numeral/locales/en-gb';
import 'numeral/locales/ru';
import 'numeral/locales/lv';
import 'numeral/locales/de';

/**
 * Languages available in the application.
 */
export const availableLanguages = [
    { code: 'en', name: 'English (British)', flagCountryCode: 'gb', momentLocale: 'en-gb', numeralLocale: 'en-gb' },
    { code: 'en-US', name: 'English (British)', flagCountryCode: 'gb', momentLocale: 'en-gb', numeralLocale: 'en-gb' },
    { code: 'rus', name: 'Russian', flagCountryCode: 'rus', momentLocale: 'rus', numeralLocale: 'rus' },
    { code: 'lv', name: 'Latvian', flagCountryCode: 'lv', momentLocale: 'lv', numeralLocale: 'lv' },
    { code: 'de', name: 'German', flagCountryCode: 'de', momentLocale: 'de', numeralLocale: 'de' },
];
