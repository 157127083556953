import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDown,
    faBaby,
    faBars,
    faBed,
    faCalendarAlt,
    faCampground,
    faCheck,
    faChevronDown,
    faCircle,
    faClipboardList,
    faCogs,
    faDotCircle,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faFileImport,
    faHandsHelping,
    faHome,
    faMountain,
    faSink,
    faSortDown,
    faSwimmingPool,
    faTemperatureLow,
    faTimes,
    faToolbox,
    faTshirt,
    faUser,
    faUserTie,
    faUtensils,
    faVolumeMute, faVolumeUp,
    faWater,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";

export const configureIcons = () => {
    library.add(
        faEllipsisV,
        faEllipsisH,
        faBars,
        faCalendarAlt,
        faCircle,
        faDotCircle,
        faHome,
        faCogs,
        faToolbox,
        faFileImport,
        faExclamation,
        faArrowDown,
        faSortDown,
        faChevronDown,

        faClipboardList,
        faUserTie,
        faClock,
        faHandsHelping,
        faWindowClose,
        faTimes,
        faTemperatureLow,
        faCheck,

        faVolumeMute,
        faVolumeUp,

        faUser,
        faSink,
        faBed,
        faBaby,
        faSwimmingPool,
        faMountain,
        faCampground,
        faWater,
        faTshirt,
        faUtensils,

    );
};
