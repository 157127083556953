import "./navigation.scss";

import React from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ModalBody,
    ModalFooter,
    Navbar,
    NavItem,
} from "reactstrap";

import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {AppNavbarBrand} from "../components/AppNavbarBrand";
import {TransparentModal} from "../components/TransparentModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AnchorLink} from "gatsby-plugin-anchor-links";

export interface AppNavBarProps {
    className?: string,
    xSmall?: boolean,
    onClick: (to: string) => void,
}

export function AppNavbar(props: AppNavBarProps) {
    const {className = "", xSmall = false, onClick} = props;

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const {t, i18n} = useTranslation("index");

    const isSmall = useMediaQuery({minWidth: "380px"});

    function setLanguage(language: string) {
        i18n.changeLanguage(language);
        setModalOpen(false);
    }

    return (
        <Navbar className={className}>
            <AppNavbarBrand>{"TERRAZAS DEL DUQUE"}</AppNavbarBrand>
            <div className={"nav-items"}>
                <NavItem>
                    {/*<a href={"#Explore"} className={"d-none"}/>*/}
                    <AnchorLink
                          to={"/#Explore"}
                          className={"navlink"}>
                        {t("nav.explore", "Explore")}
                    </AnchorLink>
                </NavItem>
                <NavItem>
                    {/*<a href={"#Book"} className={"d-none"} />*/}
                    <AnchorLink
                          to={"/#Book"}
                          className={"navlink"}>
                        {t("nav.book", "Book a holiday")}
                    </AnchorLink>
                </NavItem>
                <NavItem>
                    <div className={"navlink"} style={{width: "fit-content"}} onClick={() => setModalOpen(!modalOpen)}>{i18n.language.toUpperCase()}</div>
                </NavItem>
            </div>
            <TransparentModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalBody>
                    <div className={"language-selection"}>
                        <Button onClick={() => setLanguage("en")}>{"English"}</Button>
                        <Button onClick={() => setLanguage("rus")}>{"Russian"}</Button>
                        <Button onClick={() => setLanguage("esp")}>{"Spanish"}</Button>
                        <Button onClick={() => setLanguage("de")}>{"German"}</Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"button-container"}>
                        <Button onClick={() => setModalOpen(false)}>
                            <FontAwesomeIcon icon={"times"}/>
                        </Button>
                    </div>
                </ModalFooter>
            </TransparentModal>
        </Navbar>
    );
}



export function LanguageMobile() {
    const {i18n} = useTranslation("nav");
    const [dropDownOpen, setDropDownOpen] = React.useState<boolean>(false);

    const getLanguage = (code: string) => {
        if (code.toLowerCase().includes("en")) return "EN";
        if (code.toLowerCase().includes("de")) return "DE";
        if (code.toLowerCase().includes("lv")) return "LV";
        if (code.toLowerCase().includes("ru")) return "RU";
        return "Lang";
    };
    return (
        <Dropdown className={"language-mobile"} isOpen={dropDownOpen} toggle={() => setDropDownOpen(!dropDownOpen)}>
            <DropdownToggle outline className={"language-toggle"} caret>{getLanguage(i18n.language)}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("en")}>{"EN"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("de")}>{"DE"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("lv")}>{"LV"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("rus")}>{"RUS"}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
