import React from "react";
import "./bookSection.scss";
import {GridItem} from "./GridItem";
import airBnB from "../../static/images/misc/airbnb-1.svg";
import booking from "../../static/images/misc/bookingcom-1.svg";
import {BookingIframe} from "./BookingIframe";
import {GridContainer} from "./GridContainer";
import {useTranslation} from "react-i18next";
import {Alert, Button, Container, Media} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ImageView} from "./ImageView";
import {getImage} from "../config/imageConfig";
import {useMediaQuery} from "react-responsive";
import {AnchorLink} from "gatsby-plugin-anchor-links";

export interface BookSectionProps {
    ref?: React.RefObject<HTMLDivElement>;
}

export function BookSection(props: BookSectionProps) {
    const {t} = useTranslation('index');

    const bookRef = React.useRef<HTMLDivElement>(null);
    const isDesktop = useMediaQuery({minWidth: "768px"});

    const executeScroll = () => {
        bookRef.current && bookRef.current.scrollIntoView({behavior: "smooth"});
    };

    return (
        <div className={"book-section"}>
            <GridContainer>
                <GridItem horizontal className={"full-height"} padding={0}>
                    <div className={"landing-area"}>
                        <ImageView src={getImage("DSC05202").medium}/>
                        <div className={"overlay dark"}>
                            <div className={"text-center"}>
                                <h2>{t('book.header', "Book your holiday today")}</h2>
                                <h3>{t("book.linksHeader", "Available to book on the following platforms")}</h3>
                            </div>
                            <div className={"scroll-to"}>
                                <AnchorLink to={"/#Book"}>
                                    <FontAwesomeIcon icon={"chevron-down"}/>
                                </AnchorLink>
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer className={"no-spacing booking-grid-container"}>
                <GridItem className={"booking-grid-item"} horizontal padding={4} size={"lg"}>
                    <div id={"Book"} ref={bookRef} style={{height: 0}}/>
                    <BookingIframe/>
                </GridItem>
            </GridContainer>
            <GridContainer className={"links-container"}>
                <GridItem horizontal size={"sm"}>
                    <Container className={"d-flex h-100 "}>
                        <div className={"logo-container"}>
                            <a target={"_blank"}
                               rel="noopener noreferrer"
                               href={"https://www.airbnb.co.uk/rooms/49804341?source_impression_id=p3_1630151260_HrolN8bSRGklUTGN&guests=1&adults=1"}>
                                <div className={"interactive-logo"}>
                                    <Media src={airBnB}/>
                                </div>
                                <span><h4>{"airbnb"}</h4></span>
                            </a>
                            <a target={"_blank"}
                               rel="noopener noreferrer"
                               href={"https://www.booking.com/hotel/es/terrazas-del-duque-ii-apartment.en-gb.html"}>
                                <div className={"interactive-logo"}>
                                    <Media src={booking}/>
                                </div>
                                <span><h4>{"Booking.com"}</h4></span>
                            </a>
                        </div>
                    </Container>
                </GridItem>
            </GridContainer>
        </div>
    );
}