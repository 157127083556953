import "./gridItem.scss";
import React from "react";


export interface GridItemProps {
    children?: React.ReactNode,
    className?: string,
    size?: "xs" | "sm" | "md" | "lg" | "xl",
    horizontal?: boolean,
    padding?: number,
    ref?: React.MutableRefObject<HTMLDivElement>
}

export const GridItem = (props: GridItemProps) => {
    const {children, size = "sm", padding = 4, horizontal = false, className = "", ref} = props;
    return (
        <div className={`grid-item ${size} ${className} px-${padding} ${horizontal ? "horizontal" : ""}`} ref={ref}>
            <div className={"grid-item-contents"}>
                {children}
            </div>
        </div>
    );
};

export const GridItemTop = (props: GridItemProps) => {
    const {children, size = "md", padding = 0} = props;

    return (
        <div className={`grid-item-top ${size} p-${padding}`}>
            {children}
        </div>
    );
};