import React from "react";



export function BookingIframe () {

    const bookingRef = React.useRef<HTMLDivElement|null>(null)

    // add script elements
    React.useEffect(() => {

        if (!bookingRef?.current) return;

        const iframeElement = document.createElement("div", {is: "scriptRef"});
        iframeElement.id = 'apartmentIframe833221';
        iframeElement.className = 'apartment-iframe';

        const script = document.createElement("script");
        script.src = "https://login.smoobu.com/js/Settings/BookingToolIframe.js";
        script.type = "text/javascript";
        script.onload = () => {
            const w = window as any;
            w.BookingToolIframe.initialize(
                {"url": "https://login.smoobu.com/es/booking-tool/iframe/159765/833221",
                    "baseUrl": "https://login.smoobu.com",
                    "target": "#apartmentIframe833221"
                });
        }

        iframeElement.appendChild(script);
        bookingRef.current.appendChild(iframeElement);

        return () => {
            bookingRef.current.removeChild(iframeElement);
        }

    }, [bookingRef]);

    return (
        <div id={"#apartmentIframe833221"} className={"booking-iframe-container"} ref={bookingRef}/>
    )
}