import "./imageView.scss";

import React from "react";
import {Media} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ImageViewProps {
    src: string | any,
    alt?: string,
    lazyLoad?: boolean,
    className?: string,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    ref?: React.MutableRefObject<any>
}

export const ImageView = (props: ImageViewProps) => {
    const {src, onClick, className, alt, lazyLoad = false} = props;
    const ref = React.useRef<any>(null);

    return (
        <div className={"image-view-container"} ref={ref}>
            <Media onClick={onClick} object src={src} alt={alt} className={`image-view ${className}`}/>
        </div>
    )
};


export interface VideoViewProps {
    id: string,
    src: string | any,
    className?: string,
    autoPlay?: boolean,
    controls?: boolean,
    loop?: boolean,
    lazyLoad?: boolean,
    muted?: boolean,
    showMuteIcon?: boolean,
    loadingPoster?: any,
}

export const VideoView = (props: VideoViewProps) => {
    const {
        src,
        className,
        id,
        autoPlay = true,
        controls = false,
        lazyLoad = false,
        loop = true,
        muted: defaultMuted = true,
        loadingPoster,
        showMuteIcon = false
    } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    const [mute, setMute] = React.useState<boolean>(defaultMuted);

    const toggleMute = () => {

        const video = document.getElementById(id) as HTMLVideoElement;
        if (!video) return;
        setMute(!mute);
    }

    // hack to add muted attribute to video element to play on android
    React.useEffect(() => {

        if (!ref.current) return;

        const container = ref.current;
        const video = document.createElement('video');
        video.id = id;
        video.muted = defaultMuted; // fixes autoplay in chrome
        video.autoplay = autoPlay;
        video.loop = loop;
        video.controls = controls;
        video.className = `image-view  ${className}`;
        video.poster = loadingPoster;
        video.preload = "auto";
        video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)

        const source = document.createElement('source');
        source.src = src;
        source.type = 'video/mp4';
        video.appendChild(source);

        container.appendChild(video);

        return () => {
            container.removeChild(video);
        };
    });

    React.useEffect(() => {

        const video = document.getElementById(id) as HTMLVideoElement;
        if (!video) return;

        video.muted = mute;

    }, [mute, id]);

    React.useEffect(() => {
        const video = document.getElementById(id) as HTMLVideoElement;
        if (!video) return;

        function setMuteIcon(){
            setMute(video.muted);
        }

        video.addEventListener('volumechange', setMuteIcon)
        return () => video.removeEventListener('volumechange', setMuteIcon)
    });

    return (
        <div className={"video-container"} ref={ref}>
            {showMuteIcon && (
                <div className={"mute-toggle"} onClick={() => toggleMute()}>
                    <FontAwesomeIcon icon={mute ? "volume-mute" : "volume-up"}/>
                </div>
            )}
        </div>
    );
};

