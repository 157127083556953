import 'react-photoswipe/lib/photoswipe.css';
import "./thumbnailGallery.scss";
import React from "react";

import {Gallery, Item} from 'react-photoswipe-gallery';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {images} from "../config/imageConfig";
import { useHistory } from 'react-router-dom';


interface ResponsiveStyle {
    mainItemHeight: string,
    displayItemWidth: string,
    mainItemWidth: string,
    modalItemWidth: string,
    displayItemCount: number,
    seeMoreHeight: string,
}


export function ThumbnailGallery() {

    const [modal, setModal] = React.useState<boolean>(false);

    const {t} = useTranslation("index");
    const xs = useMediaQuery({maxWidth: "420px"});
    const sm = useMediaQuery({maxWidth: "576px"});
    const md = useMediaQuery({maxWidth: "767px"});
    const lg = useMediaQuery({maxWidth: "992px"});
    const xl = useMediaQuery({maxWidth: "1200px"});

    const toggleModal = (open: boolean) => {
        if (open) {
            window.location.hash = "#img-modal"
        }
        setModal(open);
    };

    React.useEffect(() => {
        if (window.location.hash !== "#img-modal") {
            setModal(false);
        }
    })

    const responsiveStyle: ResponsiveStyle = React.useMemo(() => {

        if (sm) return {
            displayItemCount: 2,
            displayItemWidth: '150px',
            modalItemWidth: '100%',
            mainItemHeight: "410px",
            mainItemWidth: "100%",
            seeMoreHeight: "100px",
        };
        if (md) return {
            displayItemCount: 5,
            displayItemWidth: '98px',
            modalItemWidth: '100%',
            mainItemHeight: "410px",
            mainItemWidth: "100%",
            seeMoreHeight: "65.33px",
        };
        if (lg) return {
            displayItemCount: 10,
            displayItemWidth: '134px',
            modalItemWidth: '300px',
            mainItemHeight: "410px",
            mainItemWidth: "100%",
            seeMoreHeight: "89.33px",
        };
        if (xl) return {
            displayItemCount: 12,
            displayItemWidth: '150px',
            modalItemWidth: '300px',
            mainItemHeight: "410px",
            mainItemWidth: "100%",
            seeMoreHeight: "100px",
        };
        return {
            displayItemCount: 16,
            displayItemWidth: '150px',
            modalItemWidth: '300px',
            mainItemHeight: "412px",
            mainItemWidth: "100%",
            seeMoreHeight: "100px",
        };

    }, [sm, md, lg, xl]);

    const smallItemStyles: React.CSSProperties = {
        cursor: 'pointer',
        objectFit: 'cover',
        width: responsiveStyle.displayItemWidth,
        maxHeight: '100px',
        margin: 2,
    };

    const mediumItemsStyles: React.CSSProperties = {
        ...smallItemStyles,
        width: responsiveStyle.modalItemWidth,
        maxHeight: '300px',
    };


    const displayImages = images.slice(1, responsiveStyle.displayItemCount);

    const mainItem = (
        <Item
            original={images[0].src}
            thumbnail={images[0].medium}
            width="1600"
            height="1066"
        >
            {({ref, open}) => (
                <img
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={open}
                    src={images[0].medium}
                    alt={`Terrazas del Duque`}
                    style={{
                        ...mediumItemsStyles,
                        width: lg ? '100%' : '450px',
                        maxHeight: '410px',
                        height: '410px',
                    }}
                />
            )}
        </Item>
    );

    const displayItems = sm ? [] : displayImages.map((image, index) => {
        return (
            <Item
                key={index}
                original={image.src}
                thumbnail={image.src}
                width="1600"
                height="1066"
            >
                {({ref, open}) => (
                    <img ref={ref as React.MutableRefObject<HTMLImageElement>}
                         alt={`Terrazas del Duque ${index}`}
                         onClick={open}
                         src={image.thumbnail}
                         style={smallItemStyles}
                    />
                )}
            </Item>
        );
    });

    const items = images.map((image, index) => {
        return (
            <Item
                key={index}
                original={image.src}
                thumbnail={image.thumbnail}
                width="1600"
                height="1066"
            >
                {({ref, open}) => (
                    <img loading={"lazy"} ref={ref as React.MutableRefObject<HTMLImageElement>}
                         alt={`Terrazas del Duque ${index}`}
                         onClick={open}
                         src={image.medium}
                         style={mediumItemsStyles}
                    />
                )}
            </Item>
        );
    });

    const moreButton = (
        <div
            className={"see-more-item"}
            onClick={() => toggleModal(true)}
            style={{
                width: sm ? "100%" : responsiveStyle.displayItemWidth,
                height: responsiveStyle.seeMoreHeight,
            }}
        >
            {t('gallery.seeMore', "See all photos")}
        </div>
    );

    const options = {
        history: true,
        escKey: true
    }

    return (
        <>
            <Gallery options={options}>
                <div className={"gallery-container"}>
                    {mainItem}
                    <div className={"thumbnail-gallery"}>
                        {displayItems}
                        {moreButton}
                    </div>
                </div>
            </Gallery>
            <Modal className={"gallery-modal"} isOpen={modal} toggle={() => toggleModal(!modal)}>
                <ModalHeader toggle={() => toggleModal(!modal)}/>
                <ModalBody>
                    <Gallery options={options}>
                        <div className={"thumbnail-gallery"}>
                            {items}
                        </div>
                    </Gallery>
                </ModalBody>
            </Modal>
        </>
    );

}