import './transparentModal.scss';

import React from "react";
import {Modal, ModalProps} from "reactstrap";

export function TransparentModal(props: ModalProps){
    const {children, className, ...other} = props;

    return (
        <Modal className={`transparent-modal ${className}`} {...other}>
            {children}
        </Modal>
    )
}