import DSC05046M from "../../static/images/medium/DSC05046-HDR_medium.jpg";
import DSC05046 from "../../static/images/original/DSC05046-HDR.jpg";
import DSC05037 from "../../static/images/original/DSC05037-HDR.jpg";
import DSC05037T from "../../static/images/thumbs/DSC05037-HDR_thumb.jpg";
import DSC05037M from "../../static/images/medium/DSC05037-HDR_medium.jpg";
import DSC05046T from "../../static/images/thumbs/DSC05046-HDR_thumb.jpg";
import DSC05049 from "../../static/images/original/DSC05049-HDR.jpg";
import DSC05049T from "../../static/images/thumbs/DSC05049-HDR_thumb.jpg";
import DSC05049M from "../../static/images/medium/DSC05049-HDR_medium.jpg";
import DSC05052 from "../../static/images/original/DSC05052-HDR.jpg";
import DSC05052T from "../../static/images/thumbs/DSC05052-HDR_thumb.jpg";
import DSC05052M from "../../static/images/medium/DSC05052-HDR_medium.jpg";
import DSC05055 from "../../static/images/original/DSC05055-HDR.jpg";
import DSC05055T from "../../static/images/thumbs/DSC05055-HDR_thumb.jpg";
import DSC05055M from "../../static/images/medium/DSC05055-HDR_medium.jpg";
import DSC05058 from "../../static/images/original/DSC05058-HDR.jpg";
import DSC05058T from "../../static/images/thumbs/DSC05058-HDR_thumb.jpg";
import DSC05058M from "../../static/images/medium/DSC05058-HDR_medium.jpg";
import DSC05064 from "../../static/images/original/DSC05064-HDR.jpg";
import DSC05064T from "../../static/images/thumbs/DSC05064-HDR_thumb.jpg";
import DSC05064M from "../../static/images/medium/DSC05064-HDR_medium.jpg";
import DSC05067 from "../../static/images/original/DSC05067-HDR.jpg";
import DSC05067T from "../../static/images/thumbs/DSC05067-HDR_thumb.jpg";
import DSC05067M from "../../static/images/medium/DSC05067-HDR_medium.jpg";
import DSC05070 from "../../static/images/original/DSC05070-HDR.jpg";
import DSC05070T from "../../static/images/thumbs/DSC05070-HDR_thumb.jpg";
import DSC05070M from "../../static/images/medium/DSC05070-HDR_medium.jpg";
import DSC05073 from "../../static/images/original/DSC05073-HDR.jpg";
import DSC05073T from "../../static/images/thumbs/DSC05073-HDR_thumb.jpg";
import DSC05073M from "../../static/images/medium/DSC05073-HDR_medium.jpg";
import DSC05085 from "../../static/images/original/DSC05085-HDR.jpg";
import DSC05085T from "../../static/images/thumbs/DSC05085-HDR_thumb.jpg";
import DSC05085M from "../../static/images/medium/DSC05085-HDR_medium.jpg";
import DSC05088 from "../../static/images/original/DSC05088-HDR.jpg";
import DSC05088T from "../../static/images/thumbs/DSC05088-HDR_thumb.jpg";
import DSC05088M from "../../static/images/medium/DSC05088-HDR_medium.jpg";
import DSC05091 from "../../static/images/original/DSC05091-HDR.jpg";
import DSC05091T from "../../static/images/thumbs/DSC05091-HDR_thumb.jpg";
import DSC05091M from "../../static/images/medium/DSC05091-HDR_medium.jpg";
import DSC05094 from "../../static/images/original/DSC05094-HDR.jpg";
import DSC05094T from "../../static/images/thumbs/DSC05094-HDR_thumb.jpg";
import DSC05094M from "../../static/images/medium/DSC05094-HDR_medium.jpg";
import DSC05097 from "../../static/images/original/DSC05097-HDR.jpg";
import DSC05097T from "../../static/images/thumbs/DSC05097-HDR_thumb.jpg";
import DSC05097M from "../../static/images/medium/DSC05097-HDR_medium.jpg";
import DSC05100 from "../../static/images/original/DSC05100-HDR.jpg";
import DSC05100T from "../../static/images/thumbs/DSC05100-HDR_thumb.jpg";
import DSC05100M from "../../static/images/medium/DSC05100-HDR_medium.jpg";
import DSC05103 from "../../static/images/original/DSC05103-HDR.jpg";
import DSC05103T from "../../static/images/thumbs/DSC05103-HDR_thumb.jpg";
import DSC05103M from "../../static/images/medium/DSC05103-HDR_medium.jpg";
import DSC05106 from "../../static/images/original/DSC05106-HDR.jpg";
import DSC05106T from "../../static/images/thumbs/DSC05106-HDR_thumb.jpg";
import DSC05106M from "../../static/images/medium/DSC05106-HDR_medium.jpg";
import DSC05109 from "../../static/images/original/DSC05109-HDR.jpg";
import DSC05109T from "../../static/images/thumbs/DSC05109-HDR_thumb.jpg";
import DSC05109M from "../../static/images/medium/DSC05109-HDR_medium.jpg";
import DSC05112 from "../../static/images/original/DSC05112-HDR.jpg";
import DSC05112T from "../../static/images/thumbs/DSC05112-HDR_thumb.jpg";
import DSC05112M from "../../static/images/medium/DSC05112-HDR_medium.jpg";
import DSC05115 from "../../static/images/original/DSC05115-HDR.jpg";
import DSC05115T from "../../static/images/thumbs/DSC05115-HDR_thumb.jpg";
import DSC05115M from "../../static/images/medium/DSC05115-HDR_medium.jpg";
import DSC05118 from "../../static/images/original/DSC05118-HDR.jpg";
import DSC05118T from "../../static/images/thumbs/DSC05118-HDR_thumb.jpg";
import DSC05118M from "../../static/images/medium/DSC05118-HDR_medium.jpg";
import DSC05121 from "../../static/images/original/DSC05121-HDR.jpg";
import DSC05121T from "../../static/images/thumbs/DSC05121-HDR_thumb.jpg";
import DSC05121M from "../../static/images/medium/DSC05121-HDR_medium.jpg";
import DSC05124 from "../../static/images/original/DSC05124-HDR.jpg";
import DSC05124T from "../../static/images/thumbs/DSC05124-HDR_thumb.jpg";
import DSC05124M from "../../static/images/medium/DSC05124-HDR_medium.jpg";
import DSC05127 from "../../static/images/original/DSC05127-HDR.jpg";
import DSC05127T from "../../static/images/thumbs/DSC05127-HDR_thumb.jpg";
import DSC05127M from "../../static/images/medium/DSC05127-HDR_medium.jpg";
import DSC05130 from "../../static/images/original/DSC05130-HDR.jpg";
import DSC05130T from "../../static/images/thumbs/DSC05130-HDR_thumb.jpg";
import DSC05130M from "../../static/images/medium/DSC05130-HDR_medium.jpg";
import DSC05133 from "../../static/images/original/DSC05133-HDR.jpg";
import DSC05133T from "../../static/images/thumbs/DSC05133-HDR_thumb.jpg";
import DSC05133M from "../../static/images/medium/DSC05133-HDR_medium.jpg";
import DSC05136 from "../../static/images/original/DSC05136-HDR.jpg";
import DSC05136T from "../../static/images/thumbs/DSC05136-HDR_thumb.jpg";
import DSC05136M from "../../static/images/medium/DSC05136-HDR_medium.jpg";
import DSC05139 from "../../static/images/original/DSC05139-HDR.jpg";
import DSC05139T from "../../static/images/thumbs/DSC05139-HDR_thumb.jpg";
import DSC05139M from "../../static/images/medium/DSC05139-HDR_medium.jpg";
import DSC05142 from "../../static/images/original/DSC05142-HDR.jpg";
import DSC05142T from "../../static/images/thumbs/DSC05142-HDR_thumb.jpg";
import DSC05142M from "../../static/images/medium/DSC05142-HDR_medium.jpg";
import DSC05148 from "../../static/images/original/DSC05148-HDR.jpg";
import DSC05148T from "../../static/images/thumbs/DSC05148-HDR_thumb.jpg";
import DSC05148M from "../../static/images/medium/DSC05148-HDR_medium.jpg";
import DSC05151 from "../../static/images/original/DSC05151-HDR.jpg";
import DSC05151T from "../../static/images/thumbs/DSC05151-HDR_thumb.jpg";
import DSC05151M from "../../static/images/medium/DSC05151-HDR_medium.jpg";
import DSC05154 from "../../static/images/original/DSC05154-HDR.jpg";
import DSC05154T from "../../static/images/thumbs/DSC05154-HDR_thumb.jpg";
import DSC05154M from "../../static/images/medium/DSC05154-HDR_medium.jpg";
import DSC05157 from "../../static/images/original/DSC05157-HDR.jpg";
import DSC05157T from "../../static/images/thumbs/DSC05157-HDR_thumb.jpg";
import DSC05157M from "../../static/images/medium/DSC05157-HDR_medium.jpg";
import DSC05160 from "../../static/images/original/DSC05160-HDR.jpg";
import DSC05160T from "../../static/images/thumbs/DSC05160-HDR_thumb.jpg";
import DSC05160M from "../../static/images/medium/DSC05160-HDR_medium.jpg";
import DSC05163 from "../../static/images/original/DSC05163-HDR.jpg";
import DSC05163T from "../../static/images/thumbs/DSC05163-HDR_thumb.jpg";
import DSC05163M from "../../static/images/medium/DSC05163-HDR_medium.jpg";
import DSC05166 from "../../static/images/original/DSC05166-HDR.jpg";
import DSC05166T from "../../static/images/thumbs/DSC05166-HDR_thumb.jpg";
import DSC05166M from "../../static/images/medium/DSC05166-HDR_medium.jpg";
import DSC05169 from "../../static/images/original/DSC05169-HDR.jpg";
import DSC05169T from "../../static/images/thumbs/DSC05169-HDR_thumb.jpg";
import DSC05169M from "../../static/images/medium/DSC05169-HDR_medium.jpg";
import DSC05172 from "../../static/images/original/DSC05172-HDR.jpg";
import DSC05172T from "../../static/images/thumbs/DSC05172-HDR_thumb.jpg";
import DSC05172M from "../../static/images/medium/DSC05172-HDR_medium.jpg";
import DSC05175 from "../../static/images/original/DSC05175-HDR.jpg";
import DSC05175T from "../../static/images/thumbs/DSC05175-HDR_thumb.jpg";
import DSC05175M from "../../static/images/medium/DSC05175-HDR_medium.jpg";
import DSC05178 from "../../static/images/original/DSC05178-HDR.jpg";
import DSC05178T from "../../static/images/thumbs/DSC05178-HDR_thumb.jpg";
import DSC05178M from "../../static/images/medium/DSC05178-HDR_medium.jpg";
import DSC05181 from "../../static/images/original/DSC05181-HDR.jpg";
import DSC05181T from "../../static/images/thumbs/DSC05181-HDR_thumb.jpg";
import DSC05181M from "../../static/images/medium/DSC05181-HDR_medium.jpg";
import DSC05184 from "../../static/images/original/DSC05184-HDR.jpg";
import DSC05184T from "../../static/images/thumbs/DSC05184-HDR_thumb.jpg";
import DSC05184M from "../../static/images/medium/DSC05184-HDR_medium.jpg";
import DSC05187 from "../../static/images/original/DSC05187-HDR.jpg";
import DSC05187T from "../../static/images/thumbs/DSC05187-HDR_thumb.jpg";
import DSC05187M from "../../static/images/medium/DSC05187-HDR_medium.jpg";
import DSC05190 from "../../static/images/original/DSC05190-HDR.jpg";
import DSC05190T from "../../static/images/thumbs/DSC05190-HDR_thumb.jpg";
import DSC05190M from "../../static/images/medium/DSC05190-HDR_medium.jpg";
import DSC05193 from "../../static/images/original/DSC05193-HDR.jpg";
import DSC05193T from "../../static/images/thumbs/DSC05193-HDR_thumb.jpg";
import DSC05193M from "../../static/images/medium/DSC05193-HDR_medium.jpg";
import DSC05196 from "../../static/images/original/DSC05196-HDR.jpg";
import DSC05196T from "../../static/images/thumbs/DSC05196-HDR_thumb.jpg";
import DSC05196M from "../../static/images/medium/DSC05196-HDR_medium.jpg";
import DSC05199 from "../../static/images/original/DSC05199-HDR.jpg";
import DSC05199T from "../../static/images/thumbs/DSC05199-HDR_thumb.jpg";
import DSC05199M from "../../static/images/medium/DSC05199-HDR_medium.jpg";
import DSC05202 from "../../static/images/original/DSC05202-HDR.jpg";
import DSC05202T from "../../static/images/thumbs/DSC05202-HDR_thumb.jpg";
import DSC05202M from "../../static/images/medium/DSC05202-HDR_medium.jpg";
import DSC05205 from "../../static/images/original/DSC05205-HDR.jpg";
import DSC05205T from "../../static/images/thumbs/DSC05205-HDR_thumb.jpg";
import DSC05205M from "../../static/images/medium/DSC05205-HDR_medium.jpg";
import DSC05208 from "../../static/images/original/DSC05208-HDR.jpg";
import DSC05208T from "../../static/images/thumbs/DSC05208-HDR_thumb.jpg";
import DSC05208M from "../../static/images/medium/DSC05208-HDR_medium.jpg";
import DSC05211 from "../../static/images/original/DSC05211-HDR.jpg";
import DSC05211T from "../../static/images/thumbs/DSC05211-HDR_thumb.jpg";
import DSC05211M from "../../static/images/medium/DSC05211-HDR_medium.jpg";
import DSC05214 from "../../static/images/original/DSC05214-HDR.jpg";
import DSC05214T from "../../static/images/thumbs/DSC05214-HDR_thumb.jpg";
import DSC05214M from "../../static/images/medium/DSC05214-HDR_medium.jpg";
import parking_medium from "../../static/images/medium/parking_medium.png";
import gate2_medium from "../../static/images/medium/gate2_medium.jpg";
import night1_medium from "../../static/images/medium/night1_medium.jpg";
import night2_medium from "../../static/images/medium/night2_medium.jpg";
import night3_medium from "../../static/images/medium/night3_medium.jpg";
import night4_medium from "../../static/images/medium/night4_medium.jpg";
import night1 from "../../static/images/original/night1.jpg";
import night2 from "../../static/images/original/night2.jpg";
import night3 from "../../static/images/original/night3.jpg";
import night4 from "../../static/images/original/night4.jpg";
import night1_thumb from "../../static/images/thumbs/night1_thumb.jpg";
import night2_thumb from "../../static/images/thumbs/night2_thumb.jpg";
import night3_thumb from "../../static/images/thumbs/night3_thumb.jpg";
import night4_thumb from "../../static/images/thumbs/night4_thumb.jpg";


export interface ImageSrc {
    name?: string,
    src: string,
    thumbnail: string,
    medium?: string,
}

const imagesToLoad: Array<ImageSrc> = [
    {
        name: 'DSC05046',
        src: DSC05046,
        thumbnail: DSC05046T,
        medium: DSC05046M,
    },
    {
        name: 'DSC05037',
        src: DSC05037,
        thumbnail: DSC05037T,
        medium: DSC05037M,
    },
    {
        name: 'DSC05049',
        src: DSC05049,
        thumbnail: DSC05049T,
        medium: DSC05049M,
    },
    {
        name: 'DSC05052',
        src: DSC05052,
        thumbnail: DSC05052T,
        medium: DSC05052M,
    },
    {
        name: 'DSC05055',
        src: DSC05055,
        thumbnail: DSC05055T,
        medium: DSC05055M,
    },
    {
        name: 'DSC05058',
        src: DSC05058,
        thumbnail: DSC05058T,
        medium: DSC05058M,
    },
    {
        name: 'DSC05064',
        src: DSC05064,
        thumbnail: DSC05064T,
        medium: DSC05064M,
    },
    {
        name: 'DSC05067',
        src: DSC05067,
        thumbnail: DSC05067T,
        medium: DSC05067M,
    },
    {
        name: 'DSC05070',
        src: DSC05070,
        thumbnail: DSC05070T,
        medium: DSC05070M,
    },
    {
        name: 'DSC05073',
        src: DSC05073,
        thumbnail: DSC05073T,
        medium: DSC05073M,
    },
    {
        name: 'DSC05085',
        src: DSC05085,
        thumbnail: DSC05085T,
        medium: DSC05085M,
    },
    {
        name: 'DSC05088',
        src: DSC05088,
        thumbnail: DSC05088T,
        medium: DSC05088M,
    },
    {
        name: 'DSC05091',
        src: DSC05091,
        thumbnail: DSC05091T,
        medium: DSC05091M,
    },
    {
        name: 'DSC05094',
        src: DSC05094,
        thumbnail: DSC05094T,
        medium: DSC05094M,
    },
    {
        name: 'DSC05097',
        src: DSC05097,
        thumbnail: DSC05097T,
        medium: DSC05097M,
    },
    {
        name: 'DSC05100',
        src: DSC05100,
        thumbnail: DSC05100T,
        medium: DSC05100M,
    },
    {
        name: 'DSC05103',
        src: DSC05103,
        thumbnail: DSC05103T,
        medium: DSC05103M,
    },
    {
        name: 'DSC05106',
        src: DSC05106,
        thumbnail: DSC05106T,
        medium: DSC05106M,
    },
    {
        name: 'DSC05109',
        src: DSC05109,
        thumbnail: DSC05109T,
        medium: DSC05109M,
    },
    {
        name: 'DSC05112',
        src: DSC05112,
        thumbnail: DSC05112T,
        medium: DSC05112M,
    },
    {
        name: 'DSC05115',
        src: DSC05115,
        thumbnail: DSC05115T,
        medium: DSC05115M,
    },
    {
        name: 'DSC05118',
        src: DSC05118,
        thumbnail: DSC05118T,
        medium: DSC05118M,
    },
    {
        name: 'DSC05121',
        src: DSC05121,
        thumbnail: DSC05121T,
        medium: DSC05121M,
    },
    {
        name: 'DSC05124',
        src: DSC05124,
        thumbnail: DSC05124T,
        medium: DSC05124M,
    },
    {
        name: 'DSC05127',
        src: DSC05127,
        thumbnail: DSC05127T,
        medium: DSC05127M,
    },
    {
        name: 'DSC05130',
        src: DSC05130,
        thumbnail: DSC05130T,
        medium: DSC05130M,
    },
    {
        name: 'DSC05133',
        src: DSC05133,
        thumbnail: DSC05133T,
        medium: DSC05133M,
    },
    {
        name: 'DSC05136',
        src: DSC05136,
        thumbnail: DSC05136T,
        medium: DSC05136M,
    },
    {
        name: 'DSC05139',
        src: DSC05139,
        thumbnail: DSC05139T,
        medium: DSC05139M,
    },
    {
        name: 'DSC05142',
        src: DSC05142,
        thumbnail: DSC05142T,
        medium: DSC05142M,
    },
    {
        name: 'DSC05148',
        src: DSC05148,
        thumbnail: DSC05148T,
        medium: DSC05148M,
    },
    {
        name: 'DSC05151',
        src: DSC05151,
        thumbnail: DSC05151T,
        medium: DSC05151M,
    },
    {
        name: 'DSC05154',
        src: DSC05154,
        thumbnail: DSC05154T,
        medium: DSC05154M,
    },
    {
        name: 'DSC05157',
        src: DSC05157,
        thumbnail: DSC05157T,
        medium: DSC05157M,
    },
    {
        name: 'DSC05160',
        src: DSC05160,
        thumbnail: DSC05160T,
        medium: DSC05160M,
    },
    {
        name: 'DSC05163',
        src: DSC05163,
        thumbnail: DSC05163T,
        medium: DSC05163M,
    },
    {
        name: 'DSC05166',
        src: DSC05166,
        thumbnail: DSC05166T,
        medium: DSC05166M,
    },
    {
        name: 'DSC05169',
        src: DSC05169,
        thumbnail: DSC05169T,
        medium: DSC05169M,
    },
    {
        name: 'DSC05172',
        src: DSC05172,
        thumbnail: DSC05172T,
        medium: DSC05172M,
    },
    {
        name: 'DSC05175',
        src: DSC05175,
        thumbnail: DSC05175T,
        medium: DSC05175M,
    },
    {
        name: 'DSC05178',
        src: DSC05178,
        thumbnail: DSC05178T,
        medium: DSC05178M,
    },
    {
        name: 'DSC05181',
        src: DSC05181,
        thumbnail: DSC05181T,
        medium: DSC05181M,
    },
    {
        name: 'DSC05184',
        src: DSC05184,
        thumbnail: DSC05184T,
        medium: DSC05184M,
    },
    {
        name: 'DSC05187',
        src: DSC05187,
        thumbnail: DSC05187T,
        medium: DSC05187M,
    },
    {
        name: 'DSC05190',
        src: DSC05190,
        thumbnail: DSC05190T,
        medium: DSC05190M,
    },
    {
        name: 'DSC05193',
        src: DSC05193,
        thumbnail: DSC05193T,
        medium: DSC05193M,
    },
    {
        name: 'DSC05196',
        src: DSC05196,
        thumbnail: DSC05196T,
        medium: DSC05196M,
    },
    {
        name: 'DSC05199',
        src: DSC05199,
        thumbnail: DSC05199T,
        medium: DSC05199M,
    },
    {
        name: 'DSC05202',
        src: DSC05202,
        thumbnail: DSC05202T,
        medium: DSC05202M,
    },
    {
        name: 'DSC05205',
        src: DSC05205,
        thumbnail: DSC05205T,
        medium: DSC05205M,
    },
    {
        name: 'DSC05208',
        src: DSC05208,
        thumbnail: DSC05208T,
        medium: DSC05208M,
    },
    {
        name: 'DSC05211',
        src: DSC05211,
        thumbnail: DSC05211T,
        medium: DSC05211M,
    },
    {
        name: 'DSC05214',
        src: DSC05214,
        thumbnail: DSC05214T,
        medium: DSC05214M,
    },
    {
        name: 'parking_medium',
        src: parking_medium,
        thumbnail: parking_medium,
        medium: parking_medium,
    },
    {
        name: 'gate2_medium',
        src: gate2_medium,
        thumbnail: gate2_medium,
        medium: gate2_medium,
    },
    {
        name: 'night1',
        src: night1,
        thumbnail: night1_thumb,
        medium: night1_medium,
    },
    {
        name: 'night2',
        src: night2,
        thumbnail: night2_thumb,
        medium: night2_medium,
    },
    {
        name: 'night3',
        src: night3,
        thumbnail: night3_thumb,
        medium: night3_medium,
    },
    {
        name: 'night4',
        src: night4,
        thumbnail: night4_thumb,
        medium: night4_medium,
    },
];


export const images: Array<ImageSrc> = [];

export const getImage = (name: string): ImageSrc => images.find(i => i.name === name)

export const configureImages = () => {
    imagesToLoad.forEach((i) => images.push(i));
}
