import "./mapContainer.scss";
import React from "react";

import gmap from "../../static/images/misc/gmaps.png";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {ImageView} from "./ImageView";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";

interface MapContainerProps {
    google?: any
}

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '400px'
};

export const MapContainer = (props: MapContainerProps) => {
    const {google} = props;
    const [loadMap, setLoadMap] = React.useState<boolean>(false);

    const {t} = useTranslation('index');

    if (!loadMap) {
        return (
            <div className={"map-placeholder"}>
                <ImageView src={gmap} onClick={() => setLoadMap(true)}/>
                <Button className={"load-map-button"} onClick={() => setLoadMap(true)}>
                    {t('explore.map.loadMap', 'Browse map')}
                </Button>
            </div>
        )
    }

    return  (

        <Map
            style={containerStyle}
            containerStyle={containerStyle}
            google={google}
            zoom={15}
            initialCenter={{
                lat:  28.09434360073816,
                lng: -16.73791906159479
            }}
        >
            <Marker
                title={'Terrazas del Duque'}
                name={'Terrazas del Duque'}
                position={{lat: 28.09434360073816, lng: -16.73791906159479}} />
        </Map>
    );
};


export default GoogleApiWrapper({
    apiKey: 'AIzaSyCPqLGLXtALWOP0ElrgDrWMUg1TpMzn6Cc',
})(MapContainer);