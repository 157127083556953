import {isNumber} from "util";
import i18n from "i18next";
import numeral from 'numeral';
import {initReactI18next} from "react-i18next";
import moment, {isMoment} from "moment";
import {availableLanguages} from "./languageConfig";
import en from "./translations-en.json";
import de from "./translations-de.json";
import esp from "./translations-esp.json";
import rus from "./translations-rus.json";

export function configurei18n() {
    i18n
        // .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en',
            //debug: true,
            resources: {
                en: en,
                enUs: en,
                esp: esp,
                rus: rus,
                de: de,

            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default

                format: function (value, format, lang) {
                    // Handle dates with moment.
                    if (value instanceof Date) {
                        return moment(value).format(format);
                    } else if (isMoment(value)) {
                        return moment(value).format(format);
                    }

                    // Handle numbers with numeral.
                    if (value instanceof Number) {
                        return numeral(value).format(format);
                    } else if (isNumber(value)) {
                        return numeral(value).format(format);
                    }

                    return value;
                }
            }
        });

    // Keep the moment and numeral formats in sync with the i18n formats.
    i18n.on('languageChanged', (lng) => {
        const thisLanguage = availableLanguages.find(item => item.code === lng);

        moment.locale(thisLanguage?.momentLocale ?? lng);
        numeral.locale(thisLanguage?.numeralLocale ?? lng);
    });

    return i18n;
}
