import React from "react";
import moment from "moment";
import * as queryString from "querystring";
import {PageProps} from "gatsby";

interface AdParams {
    showLink?: string,
    dateFrom?: string,
    dateTo?: string,
    msg?: string,
}

export interface AdMessage {
    showAlert?: boolean,
    message?: string,
}

export function useAdTrafficActions(pageProps: PageProps): AdMessage | undefined {
    const {location} = pageProps;
    const {dateTo, dateFrom, msg, showAlert} = queryString.parse(location.search);

    return React.useMemo(() => {
        if (!showAlert) return {};

        let message = '';

        const dFrom = moment(dateFrom);
        const dTo = moment(dateTo);
        if (dateFrom && dateTo && dFrom.isValid() && dTo.isValid()) {
            message = `Special offer from ${dFrom.format("DD MMMM YYYY")} to ${dTo.format("DD MMMM YYYY")}. `;
        }
        message += (msg ?? "");

        return {
            showAlert: !!showAlert,
            message: message,
        };
    }, [dateTo, dateFrom, msg, showAlert]);

}