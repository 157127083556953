import "./gridContainer.scss";

import React from "react";
import {Container} from "reactstrap";

export interface GridContainerProps {
    className?: string,
    children?: React.ReactNode
}

export const GridContainer = (props: GridContainerProps) => {
    const {className = "", children} = props;

    return (
        <div className={`grid-container ${className}`}>
            {children}
        </div>
    )
}
