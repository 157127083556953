import "./index.scss";
import * as React from 'react';
import {navigate, PageProps} from "gatsby";
import {useTranslation} from "react-i18next";
import Layout from "../layouts";
import {GridContainer} from "../components/GridContainer";
import {ImageView, VideoView} from "../components/ImageView";
import tourVideo from "../../static/videos/tour.mp4";
import gate1 from "../../static/images/medium/gate1_medium.jpg";
import videPoster from "../../static/images/misc/video-poster.png";
import videoStill from "../../static/images/video-still.png";
import {GridItem, GridItemTop} from "../components/GridItem";
import {useMediaQuery} from "react-responsive";
import {Footer} from "../layouts/Footer";
import {Alert, Button, Col, Container, Row, Table} from "reactstrap";
import {AppNavbar} from "../layouts/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MapContainer from "../components/MapContainer";
import {BookSection} from "../components/BookSection";
import {ThumbnailGallery} from "../components/ThumbnailGallery";
import {getImage} from "../config/imageConfig";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {useAdTrafficActions} from "../shared/useAdTrafficActions/useAdTrafficActions";
import {StickyToolbar} from "../components/StickyToolbar";


const cloudinary = {
    beachSm: "https://res.cloudinary.com/terazzas-del-duque/video/upload/v1627112701/Terazzas%20Website/beach_1_small_qrxefd.mp4",
    beachLg: "https://res.cloudinary.com/terazzas-del-duque/video/upload/v1627112712/Terazzas%20Website/beach_1_hybw1o.mp4"
}


export default function IndexPage(props: PageProps) {
    const {location} = props;
    const {t} = useTranslation('index');

    const exploreRef = React.useRef<HTMLDivElement>(null);
    const moreDetailsRef = React.useRef<HTMLDivElement>(null);

    const isDesktop = useMediaQuery({minWidth: "768px"});

    const {showAlert, message} = useAdTrafficActions(props);

    const executeScroll = (to: string) => {
        if (!exploreRef?.current) return;

        switch (to) {
            case "explore":
                exploreRef.current && exploreRef.current.scrollIntoView({behavior: "smooth"});
                break;
            case "more":
                moreDetailsRef.current && moreDetailsRef.current.scrollIntoView({behavior: "smooth"});
                break;
            case "book":
                const element = document.getElementById("apartmentIframe833221");
                element && element.scrollIntoView({behavior: "smooth"});
                break;
        }
    };

    const onNavigationClick = (to: string) => {
        executeScroll(to);
    };

    // effect to scroll to explore if we get here form an ad
    React.useEffect(() => {
        if (!showAlert) return;
        console.log("Will SCroll")
        if (!exploreRef.current) return;
        exploreRef.current && exploreRef.current.scrollIntoView({behavior: "smooth"});


    }, [showAlert])


    return (
        <Layout location={location}>
            <GridItem className={"full-height"} horizontal padding={0} size={"xl"}>
                <div className={"landing-area"}>
                    <VideoView id={"landing-video"}
                               className={"landing-video"}
                               src={isDesktop ? cloudinary.beachLg : cloudinary.beachSm}
                               loadingPoster={videoStill}/>
                    <div className={"overlay"}>
                        {/*<StickyToolbar>*/}
                            <AppNavbar onClick={onNavigationClick}/>
                        {/*</StickyToolbar>*/}
                        <div className={"landing-message"}>
                            <h1>{t('landing.header', "Luxury apartment to rent in Tenerife")}</h1>
                        </div>
                        <div className={"attribution"}>
                            {"Video by Ruvim Miksanskiy from Pexels"}
                        </div>
                        <div className={"scroll-to"}>
                            <AnchorLink to={"/#Explore"}>
                                <FontAwesomeIcon icon={"chevron-down"}/>
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            </GridItem>
            <Container>
                <div id={"Explore"} ref={exploreRef} style={{height: 0}}/>
                <GridContainer>
                    <GridItem horizontal>
                        <GridItemTop size={"md"}>
                            <ImageView src={gate1}/>
                        </GridItemTop>
                        {showAlert && (
                            <Alert color={"info"}>
                                {message ?? "'Limited offer available!'"}
                                <AnchorLink className={"ml-2"} to={"/#Book"}>
                                    {t('bookNow', 'Book Now!')}
                                </AnchorLink>
                            </Alert>
                        )}
                    </GridItem>
                    <GridItem size={"md"}>
                        <GridItemTop size={"md"}>
                            <VideoView
                                lazyLoad
                                controls
                                muted
                                autoPlay
                                showMuteIcon
                                src={tourVideo}
                                loadingPoster={videPoster}
                                id={"tour-video"}
                                className={"tour-video"}/>
                        </GridItemTop>
                        <h3>{t("explore.welcomeHeader", "Luxury apartment to rent in Tenerife")}</h3>
                        <p>{t("explore.paragraph", "",)}</p>
                    </GridItem>
                    <GridItem size={"md"}>
                            <p>{t("explore.description", "",)}</p>
                            <p>{t("explore.details1", "",)}</p>
                            <p>{t("explore.details2", "",)}</p>
                    </GridItem>
                    <GridItem horizontal padding={0}>
                        <ThumbnailGallery/>
                    </GridItem>
                    <GridItem size={"md"}>
                        <GridItemTop size={"lg"}>
                            <MapContainer/>
                        </GridItemTop>
                        <div>
                            <h3>{t("explore.map.header", "")}</h3>
                            <p>{t("explore.map.paragraph", "")}</p>
                            <a href="https://www.skylinewebcams.com/en/webcam/espana/canarias/santa-cruz-de-tenerife/playa-del-duque.html"
                               target="_blank">{t("explore.map.webcam", "See live webcam from the beach.")}</a>
                        </div>
                    </GridItem>
                    <GridItem size={"md"}>
                        <GridItemTop size={"lg"}>
                            <ImageView src={getImage("DSC05205").medium}/>
                        </GridItemTop>
                        <div>
                            <p>{t("explore.pool.paragraph1", "")}</p>
                            <p>{t("explore.pool.paragraph2", "")}</p>

                        </div>
                    </GridItem>
                </GridContainer>
            </Container>
            <GridContainer className={"table-container"}>
                <GridItem className={"table-grid-item p-4"} horizontal size={isDesktop ? "md" : "lg"}>
                    <Container className={"features-container"}>
                        <Row><h3>{t("explore.facilities.header", "Features and Amenities")}</h3></Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Table borderless>
                                    <tbody>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"bed"}/></th>
                                        <td>{t("explore.facilities.bedrooms", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"sink"}/></th>
                                        <td>{t("explore.facilities.bathrooms", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"utensils"}/></th>
                                        <td>{t("explore.facilities.kitchen", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"user"}/></th>
                                        <td>{t("explore.facilities.accommodates", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"swimming-pool"}/></th>
                                        <td>{t("explore.facilities.swimming", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"baby"}/></th>
                                        <td>{t("explore.facilities.child", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"campground"}/></th>
                                        <td>{t("explore.facilities.wifi", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"temperature-low"}/></th>
                                        <td>{t("explore.facilities.airConditioning", "")}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm={12} md={6}>
                                <Table borderless>
                                    <tbody>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.parking", "Dedicated parking")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.tv", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.social", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.kitchenEquipment", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.access", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.outdoor1", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.outdoor2", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.security1", "")}</td>
                                    </tr>
                                    <tr>
                                        <th><FontAwesomeIcon icon={"check"}/></th>
                                        <td>{t("explore.facilities.security2", "")}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className={"scroll-to"}>
                                    <div className={"d-flex flex-column"}>
                                        {t("nav.seeMoreAndBook", "See More & Book")}
                                        <div className={"scroll-to"}>
                                            <AnchorLink to={"/#Book"}>
                                                <FontAwesomeIcon icon={"chevron-down"}/>
                                            </AnchorLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </GridItem>
            </GridContainer>
            <Container>
                <div id={"MoreDetails"} ref={moreDetailsRef} style={{height: 0}}/>
                <GridContainer className={"amenities-photos"}>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("night1").src}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("parking_medium").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05091").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05133").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05118").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05103").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05172").medium}/>
                    </GridItem>
                    <GridItem size={"sm"}>
                        <ImageView src={getImage("DSC05193").medium}/>
                    </GridItem>
                </GridContainer>
            </Container>
            <BookSection/>
            <Footer/>
        </Layout>


    );
}
