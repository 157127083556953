import './bootstrap.min.css';
import "./layout.scss";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import * as React from 'react';
import {Helmet} from "react-helmet";
import {configurei18n} from "../config/i18nConfig";
import {configureIcons} from "../config/iconConfig";
import {useMediaQuery} from "react-responsive";
import {configureImages} from "../config/imageConfig";


interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
    location: {
        pathname: string
    };
    children: React.ReactNode;
}

// configure languages
configurei18n();
// create icon library
configureIcons();
// create image library
configureImages();


export default function Layout(props: DefaultLayoutProps) {
    const {children} = props;

    const isSmall = useMediaQuery({minWidth: "340px"});

    return (
        <main>
            <Helmet
                title={`Rent holiday apartments in Costa Adeje Tenerife | Terrazas del Duque`}
                meta={[
                    {
                        name: 'description',
                        content: 'Luxury holiday apartment to rent in Costa Adeje, Tenerife. Terrazas Del Duque II complex.'
                    },
                    {
                        name: 'keywords',
                        content: "Rent apartment Tenerife," +
                            "Rent apartment Costa Adeje," +
                            "Rent apartment Adeje," +
                            "Rent Tenerife South," +
                            "Rent Terrazas del Duque," +
                            "Tenerife holiday rent," +
                            "Tenerife holiday rental," +
                            "Tenerife holiday apartment," +
                            "Tenerife vacation rent," +
                            "Tenerife vacation rental," +
                            "Tenerife vacation apartment,"
                    },
                ]}
            >
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-205447879-1"></script>
                <script defer>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
    
                        gtag('config', 'UA-205447879-1');
                    `}
                </script>
            </Helmet>
            {children}
        </main>
    );
}

