import React from "react";
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import moment from "moment";


export const Footer = () => {
    const {t} = useTranslation("explore")
    return (
        <footer>
            <Container className={"footer-container"}>
                <Row>
                    <Col sm={9}>
                        <div className={"d-flex flex-column"}>
                            {`Copyright ${moment().year()} | Oscar Govsha`}
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className={"d-flex flex-column"}>
                            {t("footer.accommodationEnquiries", "Accommodation Enquiries")}
                            <a href="mailto:diana@nousproperty.com">{"diana@nousproperty.com"}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={"d-none"}>
                {"2 bed apartment to rent costa adeje \n" +
                "accommodation adeje tenerife \n" +
                "accommodation candelaria tenerife \n" +
                "accommodation fanabe tenerife \n" +
                "accommodation garachico tenerife \n" +
                "accommodation house tenerife \n" +
                "accommodation in tenerife \n" +
                "accommodation in tenerife booking \n" +
                "accommodation in tenerife costa adeje \n" +
                "accommodation in tenerife golf del sur \n" +
                "accommodation in tenerife los cristianos \n" +
                "accommodation in tenerife playa de las americas \n" +
                "accommodation in tenerife south \n" +
                "accommodation in tenerife south los cristianos \n" +
                "accommodation in tenerife with hot tub \n" +
                "accommodation near tenerife south airport \n" +
                "accommodation tenerife mountain \n" +
                "accommodation tenerife north \n" +
                "accommodation tenerife self catering \n" +
                "accommodation tenerife south airport \n" +
                "accommodation to rent tenerife \n" +
                "accommodation vilaflor tenerife \n" +
                "alcala tenerife accommodation \n" +
                "apartment for rent costa adeje \n" +
                "apartment rental tenerife costa adeje \n" +
                "apartment rental tenerife south \n" +
                "apartment tenerife for rent \n" +
                "apartment to rent adeje tenerife \n" +
                "apartment to rent costa adeje \n" +
                "apartment to rent in tenerife las americas \n" +
                "apartment to rent tenerife costa adeje \n" +
                "apartment to rent tenerife playa de las americas \n" +
                "apartment to rent tenerife south \n" +
                "apartments costa adeje for rent \n" +
                "apartments costa adeje to rent \n" +
                "apartments for rent in terrazas del duque \n" +
                "apartments for rent tenerife south \n" +
                "apartments for sale terrazas del duque tenerife \n" +
                "apartments rent costa adeje golf \n" +
                "apartments rent panorama tenerife \n" +
                "apartments to rent in terrazas del duque tenerife \n" +
                "apartments to rent tenerife south \n" +
                "bajamar tenerife accommodation \n" +
                "best accommodation in tenerife \n" +
                "best holiday destinations tenerife \n" +
                "car for rent tenerife south airport \n" +
                "car to rent tenerife south airport \n" +
                "cheap accommodation tenerife playa de las americas \n" +
                "cheap accommodation tenerife south \n" +
                "cheap car rental tenerife south \n" +
                "gay tenerife accommodation \n" +
                "holiday accommodation tenerife canary islands \n" +
                "holiday accommodation tenerife south \n" +
                "holiday apartment to rent tenerife south \n" +
                "holiday apartments rent costa adeje tenerife \n" +
                "hotel accommodation tenerife south \n" +
                "hotel tenerife zonder vlucht \n" +
                "hotel tenerife zuid \n" +
                "hotel tenerife zuid tui \n" +
                "houses to rent tenerife south \n" +
                "how many coronavirus cases in tenerife \n" +
                "how much is an apartment in tenerife \n" +
                "how much is rent in tenerife \n" +
                "is it expensive to live in tenerife \n" +
                "is tenerife still in lockdown \n" +
                "jobs in tenerife with accommodation \n" +
                "looking to rent apartment in tenerife costa adeje \n" +
                "luxury apartment to rent costa adeje \n" +
                "luxury villas rent tenerife south \n" +
                "masca tenerife accommodation \n" +
                "orlando rent car tenerife south \n" +
                "playaway tenerife accommodation \n" +
                "private accommodation tenerife south \n" +
                "private apartments rent costa adeje tenerife \n" +
                "private rented apartments in tenerife \n" +
                "property for rent tenerife south \n" +
                "property for sale terrazas del duque costa adeje \n" +
                "rent a bike tenerife south \n" +
                "rent a car in tenerife south \n" +
                "rent a car in tenerife south airport \n" +
                "rent a car tenerife south airport avis \n" +
                "rent a moto tenerife south \n" +
                "rent an apartment in costa adeje \n" +
                "rent an apartment in tenerife playa de las americas \n" +
                "rent an apartment tenerife \n" +
                "rent apartment costa adeje \n" +
                "rent apartment costa adeje tenerife \n" +
                "rent apartment in costa adeje tenerife \n" +
                "rent apartment in tenerife \n" +
                "rent apartment in tenerife costa adeje \n" +
                "rent apartment in tenerife las americas \n" +
                "rent apartment puerto de la cruz tenerife \n" +
                "rent apartment santa cruz de tenerife \n" +
                "rent apartment tenerife \n" +
                "rent apartment tenerife 1 month \n" +
                "rent apartment tenerife costa adeje \n" +
                "rent apartment tenerife long term \n" +
                "rent apartment tenerife los cristianos \n" +
                "rent apartment tenerife playa las americas \n" +
                "rent apartment tenerife south \n" +
                "rent apartments in costa adeje \n" +
                "rent apartments tenerife las americas \n" +
                "rent car plus tenerife south airport \n" +
                "rent car tenerife south \n" +
                "rent car tenerife south airport \n" +
                "rent holiday apartment tenerife \n" +
                "rent motorbike tenerife south \n" +
                "rent terrazas del duque \n" +
                "rent terrazas del duque holiday apartment \n" +
                "rent vacation house tenerife \n" +
                "rent villa tenerife south \n" +
                "rent villas in tenerife south \n" +
                "rent villas tenerife \n" +
                "rental apartment tenerife \n" +
                "rental apartments costa adeje tenerife \n" +
                "rental apartments in costa adeje tenerife \n" +
                "rental apartments in tenerife south \n" +
                "rental villas costa adeje \n" +
                "taganana tenerife accommodation \n" +
                "tenerife accommodation \n" +
                "tenerife accommodation airbnb \n" +
                "tenerife accommodation costa adeje \n" +
                "tenerife accommodation for a month \n" +
                "tenerife accommodation for couples \n" +
                "tenerife accommodation golf del sur \n" +
                "tenerife accommodation las americas \n" +
                "tenerife accommodation los cristianos \n" +
                "tenerife accommodation only \n" +
                "tenerife accommodation playa de las americas \n" +
                "tenerife accommodation south \n" +
                "tenerife accommodation to rent \n" +
                "tenerife accommodation with hot tub \n" +
                "tenerife accommodation with pool \n" +
                "tenerife accommodation with private pool \n" +
                "tenerife b&b accommodation \n" +
                "tenerife bed and breakfast accommodation \n" +
                "tenerife best accommodation \n" +
                "tenerife cheap accommodation \n" +
                "tenerife cheap accommodation apartments \n" +
                "tenerife cheapest accommodation \n" +
                "tenerife cycling accommodation \n" +
                "tenerife flights and accommodation \n" +
                "tenerife group accommodation \n" +
                "tenerife hen do accommodation \n" +
                "tenerife holiday accommodation \n" +
                "tenerife holiday home rental \n" +
                "tenerife holiday homes rent \n" +
                "tenerife holiday rental \n" +
                "tenerife holiday rental and property sales \n" +
                "tenerife holiday rentals \n" +
                "tenerife holiday rentals in los cristianos \n" +
                "tenerife holiday rentals playa de las americas \n" +
                "tenerife holiday rentals royal gardens \n" +
                "tenerife holiday villa rental \n" +
                "tenerife holiday villa rentals \n" +
                "tenerife holidays vacation \n" +
                "tenerife hotel q10 \n" +
                "tenerife hotel zentral \n" +
                "tenerife hotel zentral center \n" +
                "tenerife jobs with accommodation \n" +
                "tenerife long stay accommodation \n" +
                "tenerife north airport accommodation \n" +
                "tenerife old town accommodation \n" +
                "tenerife private accommodation \n" +
                "tenerife rent quad bike \n" +
                "tenerife rental cars south airport \n" +
                "tenerife rural accommodation \n" +
                "tenerife sea vacation \n" +
                "tenerife self catering accommodation \n" +
                "tenerife short term accommodation \n" +
                "tenerife south rent a car \n" +
                "tenerife spa vacation \n" +
                "tenerife spain accommodation \n" +
                "tenerife spain vacation \n" +
                "tenerife spain vacation packages \n" +
                "tenerife stag do accommodation \n" +
                "tenerife vacation \n" +
                "tenerife vacation corona \n" +
                "tenerife vacation deals \n" +
                "tenerife vacation homes \n" +
                "tenerife vacation packages \n" +
                "tenerife vacation packages all inclusive \n" +
                "tenerife vacation packages from toronto \n" +
                "tenerife vacation rent \n" +
                "tenerife vacation rental \n" +
                "tenerife vacation rentals \n" +
                "tenerife vacation reviews \n" +
                "tenerife vacation tips \n" +
                "tenerife vacation travel guide expedia \n" +
                "tenerife vacation vlog \n" +
                "tenerife vacations from toronto \n" +
                "tenerife workers accommodation \n" +
                "teneriffe brisbane accommodation \n" +
                "terrazas del duque apartments to rent \n" +
                "terrazas del duque for rent \n" +
                "terrazas del duque holiday rentals \n" +
                "tripadvisor tenerife accommodation \n" +
                "tve tenerife vacation establishment \n" +
                "vacation apartment tenerife \n" +
                "vacation in tenerife spain \n" +
                "vacation on tenerife \n" +
                "vacation rentals tenerife south \n" +
                "vacation tenerife canary islands \n" +
                "vacation to tenerife \n" +
                "vacation villas tenerife \n" +
                "villas for rent tenerife costa adeje \n" +
                "villas for rent tenerife south \n" +
                "villas rent tenerife costa adeje \n" +
                "villas to rent tenerife costa adeje \n" +
                "villas to rent tenerife south \n" +
                "what's the best resort in tenerife \n"}
            </div>
        </footer>
    )
};
